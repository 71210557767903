import { config } from "@app";
import { Box, Container, Typography } from "@mui/material";

function NumeroSorteo() {

    return (
        <Box
            sx={{
                bgcolor: '#cacaca',
            }}
        >
            <Box
                sx={{
                    bgcolor: '#aeaeae',
                    height:25
                }}
            ></Box>
            <Container maxWidth="sm">
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    BILLETIZA #{config.number} 
                </Typography>
            </Container>
        </Box>
    )
}

export default NumeroSorteo;
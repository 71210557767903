import { doc } from 'firebase/firestore'
import { firestore } from './firebase';

const allowDev = true;
const isDev = process.env.NODE_ENV === 'development';
const raffleId = 'SIH8u2nLkHCNBwsjiIyl';
const raffleRef = doc(firestore, "raffles", raffleId);
const numbersPhone = (['+526421374445','+526471246951']);
export const config = {
    filterLimit: undefined,
    defaultLimit: 100, //Limite por default en la seleccion de boletos
    searchLimit: 20, //Limite por default en la seleccion de boletos
    price: 15, // Precio por boleto
    packagePrices: {
        //1: 45 // 1 boleto cuesta 42
    },
    number:6, // Numero de sorteo
    raffle_tittle: '💰SUPER EXPRESS, 500,000💰 ',
    raffle_tittle1: 'FECHA: 25 DE OCTUBRE DEL 2024',
    currency: 'MXN',
    raffleId,
    rafflePad: 5,
    isDev,
    raffleRef,
    sendToNumber: () => numbersPhone[Math.floor(Math.random() * numbersPhone.length)]
}

import { config } from "@app";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Message from "./Message";

export default function Detalles(props) {
    const { onComprar, onConsultar, puedeComprar } = props;
    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                component="h2"
                                variant="h5"
                                align="center"
                                color="text.primary"
                            >
                                {config.raffle_tittle}<br/>
                                {config.raffle_tittle1}<br/>
                                
                            </Typography>
                            <Typography component="h3" variant="p" align="center" color="text.secondary" paragraph>
                                Desde ${config.packagePrices[1] || config.price} {config.currency}
                            </Typography>
                            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            

                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h9" align="center" color="text.secondary" paragraph>
                            🛑𝘾𝙤𝙣 𝙩𝙪 𝙗𝙤𝙡𝙚𝙩𝙤 𝙡𝙞𝙦𝙪𝙞𝙙𝙖𝙙𝙤 𝙥𝙖𝙧𝙩𝙞𝙘𝙞𝙥𝙖𝙨 𝙥𝙤𝙧👇<br />
                            <Typography variant="h6" align="center" color="text.primary" paragraph>
                              🥇 💲500,000 ➕ BONO DE 100,000 PESOS<br/>
                              
                             </Typography>
                            
                            <Typography variant="h12" align="center" color="text.primary" paragraph>
                            <br/> 🗒️ 𝗡𝗢𝗧𝗔𝗦 𝗜𝗠𝗣𝗢𝗥𝗧𝗔𝗡𝗧𝗘𝗦 🗒️<br/>
                            1.COMPRAR 10 BOLETOS O MAS.
                            2.NO DESPRECIAR NUMEROS PARA APLICAR BONOS Y PAGAR DENTRO DE 3 HRS.<br/>
                            3.SE JUEGA EN BASE A ULTIMAS 5 CIFRAS 𝖫𝖮𝖳𝖤𝖱𝖨𝖠 NACIONAL. <br/>
                            4.ENVIAR COMPROBANTE 𝖴𝖭𝖨𝖢𝖠𝖬𝖤𝖭𝖳𝖤 A LA 𝖫𝖨𝖭𝖤𝖠 QUE TE DIRECCIONO EL SISTEMA. <br/>

                            </Typography>
                       
                            <br/><br/>
                            📅 SORTEO 📅<br/>
                            {config.raffle_tittle1}<br/>
                            💵{config.raffle_tittle2} {config.raffle_tittle}
                            ➕BONO DE PRONTO PAGO⚡
                            </Typography>
                        </Grid>
                    </Grid>
                    {!puedeComprar && <Message />}
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Button size="large" variant='contained' color='primary' onClick={onComprar} disabled={!puedeComprar}>Comprar</Button>
                        <Button size="large" variant='outlined' onClick={onConsultar}>Consultar boleto</Button>
                    </Stack>
                </Container>
            </Box>
        </main>
    )
}